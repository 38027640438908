const region = 'eu-west-1';

export default {
    s3: {
        REGION: region,
        BUCKET: 'license-portal-client',
    },
    apiGateway: {
        REGION: region,
        URL: 'https://pkdjpgjj4l.execute-api.eu-west-1.amazonaws.com/dev',
    },
    cognito: {
        REGION: region,
        USER_POOL_ID: "eu-west-1_TOI54ebpI",
        APP_CLIENT_ID: "1n0u8te0qvmhp2rj5ld7t5hvvq",
        IDENTITY_POOL_ID: "eu-west-1:b5f05dfb-c814-400f-8839-ae051660a3a0"
    }
}
