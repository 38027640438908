import React, { ReactNode, useState } from 'react'
import { AppBar, Button, Container, createStyles, Toolbar, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { LoginComponent } from './LoginComponent'
import { Auth } from 'aws-amplify'
import { LicensedCompaniesComponent } from "./license_generation/LicensedCompaniesComponent";

export const drawerWidth = 240

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        toolbar: theme.mixins.toolbar,
        toolbarTitle: {
            flexGrow: 1,
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
    })
)

interface Props {
    children?: ReactNode;
}

export const Layout: React.FunctionComponent<Props> = (props: Props) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const classes = useStyles()

    const onLogout = async (): Promise<void> => {
        await Auth.signOut()
        setIsAuthenticated(false)
    }

    const checkIsAuthenticated = async (): Promise<void> => {
        // setIsAuthenticated(true)
        return
        try {
            await Auth.currentSession()
            setIsAuthenticated(true)
        } finally {
        }
    }

    checkIsAuthenticated()

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" noWrap className={classes.toolbarTitle}>
                        Licensing Portal
                    </Typography>
                    {isAuthenticated ? (
                        <Button color="inherit" onClick={onLogout}>
                            Logout
                        </Button>
                    ) : null}
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Container maxWidth="xl" className={classes.container}>
                    <LoginComponent
                        isAuthenticated={isAuthenticated}
                        setIsAuthenticated={setIsAuthenticated}
                    />
                    {isAuthenticated ? <LicensedCompaniesComponent /> : null}
                </Container>
            </main>
        </div>
    )
}
