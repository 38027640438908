import React from 'react'
import { LicensedCompany } from './types'
import { Grid, TextField } from '@material-ui/core'

interface Props {
    company: LicensedCompany;
    className?: string;
    invalidateLicense: () => void;
}

export const LicensedCompanyInput: React.FunctionComponent<Props> = (props: Props) => {
    const handleChangeIme = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.company.Ime = event.target.value
        props.invalidateLicense()
    }

    const handleChangeMaticna = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.company.Maticna = event.target.value
        props.invalidateLicense()
    }

    return (
        <Grid item className={props.className}>
            <form noValidate>
                <TextField label="Ime podjetja" onChange={handleChangeIme} />
                <TextField
                    label="Matična številka"
                    onChange={handleChangeMaticna}
                    style={{ marginLeft: '10px' }}
                />
            </form>
        </Grid>
    )
}
