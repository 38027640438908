import React, { useState } from 'react'
import { Button, Grid, makeStyles, Theme } from '@material-ui/core'
import { License, LicensedCompany } from './types'
import { LicensedCompanyInput } from './LicensedCompanyInput'
import { DownloadJsonLink } from './DownloadJsonLink'
import { API } from 'aws-amplify'

const useStyles = makeStyles((theme: Theme) => ({
    gridItem: {
        marginTop: theme.spacing(2),
    },
}))

async function getLicenseForMaticna(maticna: string): Promise<string> {
    const response = await API.get('license', `/license?maticna=${maticna}`, {
        response: true,
        responseType: 'text',
    })
    return response.data
}

async function generateLicense(companies: Array<LicensedCompany>): Promise<License> {
    companies.forEach(company => {
        getLicenseForMaticna(company.Maticna).then(license => {
            company.Licenca = license
        })
    })

    for (let idx = 0; idx < companies.length; idx++) {
        const company = companies[idx]
        if (company.Maticna.length > 0) {
            company.Licenca = await getLicenseForMaticna(company.Maticna)
        }
    }

    return {
        License: {
            Podjetja: companies,
        },
    }
}

export const LicensedCompaniesComponent: React.FunctionComponent<{}> = () => {
    const styles = useStyles()
    const [companies, setCompanies] = useState<Array<LicensedCompany>>([])
    const [license, setLicense] = useState<License>({ License: { Podjetja: [] } })
    const [currentLicenseValid, setCurrentLicenseValid] = useState<boolean>(false)

    const handleGenerateLicense = (): void => {
        generateLicense(companies).then(license => setLicense(license))
        setCurrentLicenseValid(true)
    }

    const invalidateLicense = (): void => {
        setCurrentLicenseValid(false)
    }

    const handleAddNewCompany = (): void => {
        setCompanies([...companies, { Ime: '', Licenca: '', Maticna: '' }])
    }
    return (
        <Grid container direction="column" justify="center" alignContent="center">
            <Grid item>
                <p>Ustvari licenčno datoteko za stranko. Vnesi ime in matično številko podjetja.</p>
            </Grid>
            {companies.map((company, idx) => (
                <LicensedCompanyInput
                    company={company}
                    key={idx}
                    className={styles.gridItem}
                    invalidateLicense={invalidateLicense}
                />
            ))}
            <Grid item className={styles.gridItem}>
                <Button onClick={handleAddNewCompany} variant="outlined">
                    Dodaj novo podjetje
                </Button>
            </Grid>
            {currentLicenseValid ? (
                <Grid item className={styles.gridItem}>
                    <DownloadJsonLink
                        fileContents={JSON.stringify(license)}
                        fileName={'license.json'}
                    />
                </Grid>
            ) : companies.length > 0 ? (
                <Grid item className={styles.gridItem}>
                    <Button onClick={handleGenerateLicense} variant="contained" color="secondary">
                        Generiraj licenco
                    </Button>
                </Grid>
            ) : null}
        </Grid>
    )
}
