import React, { useState } from 'react'
import { Button, createStyles, makeStyles, TextField, Theme } from '@material-ui/core'
import { Auth } from 'aws-amplify'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: 250,
            },
        },
    })
)

interface Props {
    isAuthenticated: boolean
    setIsAuthenticated: (isAuthenticated: boolean) => void
}

export const LoginComponent: React.FunctionComponent<Props> = (props: Props) => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [requiresNewPassword, setRequiresNewPassword] = useState<boolean>(false)
    const [user, setUser] = useState(null)

    const classes = useStyles()

    const onSetNewPassword = async (): Promise<void> => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            await Auth.completeNewPassword(
                user, // the Cognito User Object
                newPassword
            )

            alert('Password changed! Please log in!')
            setRequiresNewPassword(false)
        } catch (e) {
            alert(e.message)
        }
    }

    const onLogin = async (): Promise<void> => {
        try {
            const user = await Auth.signIn(username, password)

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setRequiresNewPassword(true)
                setUser(user)
            } else {
                alert('Logged in!')
                props.setIsAuthenticated(true)
            }
        } catch (e) {
            alert(e.message)
        }
        // await Auth.currentSession()
    }

    if (props.isAuthenticated) return null

    if (requiresNewPassword) {
        return (
            <form className={classes.root} noValidate>
                <p>
                    Must have at least 7 characters, including at least 1 number, 1 uppercase letter
                    and 1 lowercase letter.
                </p>
                <TextField
                    variant="outlined"
                    type="password"
                    label="New Password"
                    onChange={(event): void => setNewPassword(event.target.value)}
                />
                <Button
                    variant="contained"
                    disabled={!newPassword || newPassword.length < 7}
                    color="secondary"
                    style={{ height: '56px' }}
                    onClick={onSetNewPassword}
                >
                    Change password
                </Button>
            </form>
        )
    }

    return (
        <form className={classes.root} noValidate>
            <TextField
                variant="outlined"
                label="Username"
                onChange={(event): void => setUsername(event.target.value)}
            />
            <TextField
                variant="outlined"
                type="password"
                label="Password"
                onChange={(event): void => setPassword(event.target.value)}
            />
            <Button
                variant="contained"
                disabled={!username || !password}
                color="secondary"
                style={{ height: '56px' }}
                onClick={onLogin}
            >
                Login
            </Button>
        </form>
    )
}
