import React from 'react'
import { Link } from '@material-ui/core'

interface Props {
    fileContents: string;
    fileName: string;
}

export const DownloadJsonLink: React.FunctionComponent<Props> = (props: Props) => {
    const blob = new Blob([props.fileContents], { type: 'application/json' })
    return (
        <Link href={URL.createObjectURL(blob)} download={props.fileName}>
            Prenesi licenčno datoteko
        </Link>
    )
}
